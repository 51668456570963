import type { Billable, MappedPackage } from '@/components/signup/types'

export const mapPackages = (billables: Billable[]): MappedPackage[] => {
	const packages = billables?.filter(billable => billable.billablePackageSpeed)
	const isMostExpensive = (billableId: number, billables: Billable[]): boolean => {
		const billable = billables.find(billable => billable.billableId === billableId)
		const maxPrice = Math.max(...billables.map(billable => billable.billablePrice))
		return billable?.billablePrice === maxPrice
	}

	const isSecondMostExpensive = (billableId: number, billables: Billable[]): boolean => {
		const billablesLessMostExpensive = billables.filter(billable => !isMostExpensive(billable.billableId, billables))
		const billable = billables.find(billable => billable.billableId === billableId)
		if (!billable) { return false }
		return isMostExpensive(billable.billableId, billablesLessMostExpensive)
	}

	return packages.map(({
		billableId,
		billablePackageSpeed,
		billableUnit,
		billablePrice,
		billableFeatures,
		recurringFrequency,
		uniquePlanId,
		latencyMs
	}) => ({
		id: billableId,
		packageSpeed: billablePackageSpeed,
		unit: billableUnit,
		price: billablePrice,
		features: billableFeatures,
		frequency: recurringFrequency,
		uniquePlanId,
		latencyMs,
		recommended: isMostExpensive(billableId, packages),
		mostPopular: isSecondMostExpensive(billableId, packages)
	})) || []
}
